import { ParameterCodeDataPolicy } from "constants/common/ParameterCodeDataPolicy";
import { EMPTY_PARAMETER_DETAIL_ENTITY, ParameterDetailEntity } from "domain/entity/Parameter/ParameterDetailEntity";
import { EMPTY_PARAMETER_ENTITY, ParameterEntity } from "domain/entity/Parameter/ParameterEntity";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import { ParameterRepository } from "domain/repository/parameter/ParameterRepo";
import _ from "lodash";
import { parameterDetailValidationSchema, parameterMaintValidationSchema } from "presentation/constant/Parameter/ParameterMaintenanceValidationSchema";
import { Validation } from "presentation/constant/Validation";
import { ParameterMaintenanceModel } from "presentation/model/Parameter/ParameterMaintenanceModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface ParameterMaintenanceVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<ParameterMaintenanceModel>> | ((value: SetStateAction<ParameterMaintenanceModel>) => void),
    ],
    repo: ParameterRepository,
    parameterDetailRepo: ParameterDetailRepository,
}

export const ParameterMaintenanceVM = ({ dispatch, repo, parameterDetailRepo }: ParameterMaintenanceVMProps) => {
    const [parameterMainDispatch] = dispatch;

    const onShowLoading = () => {
        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const updateSelectedRows = async (rows: ParameterEntity[]) => {

        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                selectedRows: rows,
            }
        })
    }

    const onRowDoubleClick = (entity: ParameterEntity) => {
        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: false,
                isShowDetail: true,
                currentSelectedRow: {
                    ...entity
                },
                masterState: {
                    ...prevState.masterState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: true,
                    isSaveClicked: false,
                    isSliderOpen: true,
                    allFormState: {},
                    updateParamEntity: entity
                },
                detailState: {
                    ...prevState.detailState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: false,
                    isSliderOpen: false,
                    isSelected: false,
                    allFormState: {}
                },
                subDetailState: {
                    ...prevState.subDetailState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: false,
                    isSliderOpen: false,
                    isSelected: false,
                    allFormState: {}
                }
            }
        })
    }

    const loadDropdownOption = async () => {

    }

    const searchParameter = async () => {
        await repo.getEntities().then((data) => {
            parameterMainDispatch(prevState => {
                return {
                    ...prevState,
                    tableData: data,
                    selectedRows: [],
                    currentSelectedRow: EMPTY_PARAMETER_ENTITY,
                    isBackMaster: false,
                }
            })
        }).catch((error) => {
            return [];
        })
    }

    const onAddClick = () => {
        parameterMainDispatch(prevState => ({
            ...prevState,

            detailState: {
                ...prevState.detailState,
                isTabularDataActive: false,
                isAdd: true,
                isRead: false,
                isEditable: false,
                isSliderOpen: true,
                isSelected: false,
                allFormState: {},

                updateParamDtlEntity: EMPTY_PARAMETER_DETAIL_ENTITY
            }
        }))
    };

    const onResetClick = async () => {
        parameterMainDispatch(prevState => {
            let tempParamEty: ParameterEntity = EMPTY_PARAMETER_ENTITY;
            if (!prevState.masterState.isAdd) {
                tempParamEty = prevState.currentSelectedRow
            }

            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    updateParamEntity: {
                        ...tempParamEty,
                    },
                }
            }
        })
    }

    const onSave = async (currentParamEty: ParameterEntity) => {
        const valHdrResult = await Validation(parameterMaintValidationSchema).ValidateFormOnly(currentParamEty);

        if (valHdrResult) {
            let validatedTariffResult: { [x: string]: string } = {};
            if (valHdrResult) {
                validatedTariffResult = { ...validatedTariffResult, ...valHdrResult, mandatoryCheckFail: 'Please input the missing value.' };
            }

            parameterMainDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedTariffResult
                        },

                    }
                }
            });
            return validatedTariffResult;
        } else {
            let res = await repo.updateEntity(currentParamEty);

            if (res.success) {
                onCloseClick();
            } else {
                return res.data;
            }
        }
    }

    const onSaveParamDtl = async (currentParamDtlEty: ParameterDetailEntity, isAdd: boolean, isSubParamDtl: boolean) => {
        const valHdrResult = await Validation(parameterDetailValidationSchema).ValidateFormOnly(currentParamDtlEty);

        if (valHdrResult) {
            let validatedTariffResult: { [x: string]: string } = {};
            if (valHdrResult) {
                validatedTariffResult = { ...validatedTariffResult, ...valHdrResult, mandatoryCheckFail: 'Please input the missing value.' };
            }

            parameterMainDispatch(prevState => {
                return {
                    ...prevState,
                    detailState: {
                        ...prevState.detailState,
                        allFormState: {
                            ...validatedTariffResult
                        },
                    },
                    subDetailState: {
                        ...prevState.subDetailState,
                        allFormState: {
                            ...validatedTariffResult
                        },

                    }
                }
            });
            return validatedTariffResult;
        } else {
            await (isAdd ? parameterDetailRepo.createNewParameterDtl(currentParamDtlEty) : parameterDetailRepo.updateParameterDtl(currentParamDtlEty)).then((data) => {
                if (data && data.toString().startsWith("Error:")) {
                    parameterMainDispatch(prevState => {
                        return {
                            ...prevState,
                            detailState: {
                                ...prevState.detailState,
                                allFormState: { "saveParamDtlFailed": data.toString() }
                            },
                            subDetailState: {
                                ...prevState.subDetailState,
                                allFormState: { "saveParamDtlFailed": data.toString() }
                            }
                        };
                    });
                    return { "saveParamDtlFailed": data.toString() };
                } else {
                    if (!isSubParamDtl) {
                        return parameterMainDispatch(prevState => {
                            return {
                                ...prevState,

                                detailState: {
                                    ...prevState.detailState,
                                    isSliderOpen: false,
                                    isAdd: false,
                                    isEditable: false,
                                    isRead: true,
                                    //isShowSubDtlTable: false,
                                    parameterDtlList: [],
                                    selectedRows: [],
                                    currentParamDtlEntity: prevState.detailState.currentParamDtlEntity,
                                    allFormState: {},
                                }
                            }

                        })
                    } else {
                        return parameterMainDispatch(prevState => {
                            return {
                                ...prevState,

                                detailState: {
                                    ...prevState.detailState,
                                    isSliderOpen: false,
                                    isAdd: false,
                                    isEditable: false,
                                    isRead: true,
                                    allFormState: {},
                                },
                                subDetailState: {
                                    ...prevState.subDetailState,
                                    isSliderOpen: false,
                                    isAdd: false,
                                    isEditable: false,
                                    isRead: true,
                                    parameterSubDtlList: [],
                                    selectedRows: [],
                                    updateParamSubDtlEntity: EMPTY_PARAMETER_DETAIL_ENTITY,
                                    allFormState: {},
                                }
                            }

                        })
                    }
                }
            })
        }
    }

    const onCloseClick = () => {
        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: false,
                isBackMaster: true,
                selectedRows: [],
                currentSelectedRow: { ...EMPTY_PARAMETER_ENTITY },
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    isTabularDataActive: true,
                    editingTariffCodeEntity: EMPTY_PARAMETER_ENTITY,
                    allFormState: {}
                },
                detailState: {
                    ...prevState.detailState,
                    isAdd: false,
                    isSliderOpen: false,
                    isEditable: false,
                    isRead: true,
                    isTabularDataActive: true,
                    isShowSubDtlTable: false,
                    allFormState: {},
                    updateParamDtlEntity: EMPTY_PARAMETER_DETAIL_ENTITY
                },
                subDetailState: {
                    ...prevState.subDetailState,
                    isAdd: false,
                    isSliderOpen: false,
                    isEditable: false,
                    isRead: true,
                    isTabularDataActive: true,
                    allFormState: {},
                    updateParamSubDtlEntity: EMPTY_PARAMETER_DETAIL_ENTITY
                }
            }
        });
    }

    const onSaveClicked = () => {
        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }


    const onEdit = (currentParamEty: ParameterEntity) => {
        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isRead: false,
                    isEditable: true,

                    updateParamEntity: {
                        ...currentParamEty,
                    }
                },
            }
        })
    }

    const onParamDtlCancel = () => {
        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    //currentParamDtlEntity: EMPTY_PARAMETER_DETAIL_ENTITY,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    isSliderOpen: false,
                    allFormState: {},
                    //selectedRows: []
                },
                subDetailState: {
                    ...prevState.subDetailState,
                    //currentParamSubDtlEntity: EMPTY_PARAMETER_DETAIL_ENTITY,
                    //selectedRows: [],
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    isSliderOpen: false,
                    allFormState: {}
                }
            }
        })
    }

    const onParamDtlSaveClicked = () => {
        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    const onParamSubDtlSaveClicked = () => {
        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                subDetailState: {
                    ...prevState.subDetailState,
                    isSaveClicked: true,
                    allFormState: {}
                }
            }
        })
    }

    const onParamDtlFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }


        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    currentParamDtlEntity: {
                        ...prevState.detailState.currentParamDtlEntity,
                        [fieldKey]: val
                    },
                    allFormState: {
                        ...prevState.detailState.allFormState,
                        [fieldKey]: '',
                    },
                }
            }
        })
    }

    const onParamSubDtlFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }


        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                subDetailState: {
                    ...prevState.subDetailState,
                    currentParamSubDtlEntity: {
                        ...prevState.subDetailState.currentParamSubDtlEntity,
                        [fieldKey]: val
                    },
                    allFormState: {
                        ...prevState.subDetailState.allFormState,
                        [fieldKey]: '',
                    },
                }
            }
        })
    }

    const updateSelectedParamDtlRows = async (rows: any[]) => {
        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    selectedRows: rows,
                    isShowSubDtlTable: false,
                }
            }
        })
    }

    const updateSelectedParamSubDtlRows = async (rows: any[]) => {
        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                subDetailState: {
                    ...prevState.subDetailState,
                    selectedRows: rows,
                    currentParamSubDtlEntity: rows.length === 1 ? rows[0] : EMPTY_PARAMETER_DETAIL_ENTITY
                }
            }
        })
    }

    const onParamDtlRowClick = (row: ParameterDetailEntity) => {
        parameterMainDispatch(prevState => {
            const paramEntity = prevState.currentSelectedRow;
            const isShowSubDtlTable = prevState.detailState.isSliderOpen ? false : isShowSubTable(paramEntity);

            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    currentParamDtlEntity: row,
                    isShowSubDtlTable: isShowSubDtlTable
                }
            }
        });
    }

    const isShowSubTable = (paramEty: ParameterEntity) => {
        if (paramEty) {
            const tempParamCodes: string[] = [ParameterCodeDataPolicy.RAILWAY_SETTING, ParameterCodeDataPolicy.CREDIT_NOTE_NATURE, ParameterCodeDataPolicy.TML_CODE,
            ParameterCodeDataPolicy.DG_IMCO_CODE_DISPLAY_SUPPORTING, ParameterCodeDataPolicy.TAX_CODE, ParameterCodeDataPolicy.DAILY_REPORT_EMAIL_INFO,
            ParameterCodeDataPolicy.SORTING_SEQ_SEARCH]

            const paramCode = paramEty.parameterCode as string;
            if (tempParamCodes.find(param => param === paramCode)) {
                return true;
            }
        }

        return false;
    }

    const onParamDtlEdit = (row: ParameterDetailEntity) => {
        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    currentParamDtlEntity: row,
                    isSliderOpen: true,
                    isEditable: true,
                    //isShowSubDtlTable: false               
                }
            }
        });
    }

    const onParamSubDtlEdit = (row: ParameterDetailEntity) => {
        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                subDetailState: {
                    ...prevState.subDetailState,
                    currentParamSubDtlEntity: row,
                    isSliderOpen: true,
                    isEditable: true
                }
            }
        });
    }

    const onParamDtlAdd = () => {
        parameterMainDispatch(prevState => {

            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isRead: false
                },
                detailState: {
                    ...prevState.detailState,
                    isAdd: true,
                    isSliderOpen: true,
                    //isShowSubDtlTable: false,
                    selectedRows: [],
                    currentParamDtlEntity: EMPTY_PARAMETER_DETAIL_ENTITY
                }
            }
        })
    }

    const onParamSubDtlAdd = () => {
        parameterMainDispatch(prevState => {

            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isRead: false
                },
                subDetailState: {
                    ...prevState.subDetailState,
                    isAdd: true,
                    isSliderOpen: true,
                    isEditable: false,
                    isRead: false,
                    isSaveClicked: false,
                    selectedRows: [],
                    currentParamSubDtlEntity: EMPTY_PARAMETER_DETAIL_ENTITY
                }
            }
        })
    }

    const onSearchParamDtl = async (parameterId: number) => {
        await parameterDetailRepo.searchParamDtlBySearchCriteria({ parameterId }).then((data) => {
            parameterMainDispatch(prevState => {
                return {
                    ...prevState,
                    detailState: {
                        ...prevState.detailState,
                        parameterDtlList: data,
                        selectedRows: [],
                        updateParamDtlEntity: EMPTY_PARAMETER_DETAIL_ENTITY
                    }
                }
            })
        }).catch((error) => {
            return [];
        })
    }

    const onSearchParamSubDtl = async (parentDtlId: number) => {
        await parameterDetailRepo.searchParamDtlBySearchCriteria({ parentDtlId }).then((data) => {
            parameterMainDispatch(prevState => ({
                ...prevState,
                subDetailState: {
                    ...prevState.detailState,
                    parameterSubDtlList: data,
                    selectedRows: [],
                    currentParamSubDtlEntity: EMPTY_PARAMETER_DETAIL_ENTITY
                }
            }))
        }).catch((error) => {
            return [];
        })
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        parameterMainDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                updateParamEntity: {
                    ...prevState.masterState.updateParamEntity,
                    [fieldName]: checked ? "Y" : "N",
                }
            }
        }))
    }

    const onParamDtlCheckboxChange = (checked: boolean, fieldName: string) => {
        parameterMainDispatch(prevState => ({
            ...prevState,
            detailState: {
                ...prevState.detailState,
                currentParamDtlEntity: {
                    ...prevState.detailState.currentParamDtlEntity,
                    [fieldName]: checked ? "Y" : "N",
                }
            }
        }))
    }

    const onParamSubDtlCheckboxChange = (checked: boolean, fieldName: string) => {
        parameterMainDispatch(prevState => ({
            ...prevState,
            subDetailState: {
                ...prevState.subDetailState,
                currentParamSubDtlEntity: {
                    ...prevState.subDetailState.currentParamSubDtlEntity,
                    [fieldName]: checked ? "Y" : "N",
                }
            }
        }))
    }

    const onFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        return parameterMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    updateParamEntity: {
                        ...prevState.masterState.updateParamEntity,
                        [fieldKey]: val,
                    }
                }
            }
        });
    }

    return {
        loadDropdownOption: loadDropdownOption,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onRowDoubleClick: onRowDoubleClick,
        updateSelectedRows: updateSelectedRows,
        onAddClick: onAddClick,
        onResetClick: onResetClick,
        onSave: onSave,
        onSaveParamDtl: onSaveParamDtl,
        onCloseClick: onCloseClick,
        onSaveClicked: onSaveClicked,
        onEdit: onEdit,
        onParamDtlCancel: onParamDtlCancel,
        onParamDtlSaveClicked: onParamDtlSaveClicked,
        onParamSubDtlSaveClicked: onParamSubDtlSaveClicked,
        onParamDtlFieldChange: onParamDtlFieldChange,
        onParamSubDtlFieldChange: onParamSubDtlFieldChange,
        searchParameter: searchParameter,
        onSearchParamDtl: onSearchParamDtl,
        onSearchParamSubDtl: onSearchParamSubDtl,
        updateSelectedParamDtlRows: updateSelectedParamDtlRows,
        onParamDtlRowClick: onParamDtlRowClick,
        onParamDtlAdd: onParamDtlAdd,
        onParamSubDtlAdd: onParamSubDtlAdd,
        onFieldChange: onFieldChange,
        onCheckboxChange: onCheckboxChange,
        onParamDtlCheckboxChange: onParamDtlCheckboxChange,
        onParamSubDtlCheckboxChange: onParamSubDtlCheckboxChange,
        onParamDtlEdit: onParamDtlEdit,
        onParamSubDtlEdit: onParamSubDtlEdit,
        updateSelectedParamSubDtlRows: updateSelectedParamSubDtlRows
    }
}
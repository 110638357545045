import { SelectionChangedEvent } from "ag-grid-community";
import { ParameterEntity } from "domain/entity/Parameter/ParameterEntity";
import { INITIAL_PARAMETER_COL_DEF, transferRowData } from "presentation/constant/Parameter/ParameterMaintenanceColumnDefinition";
import { ParameterMaintenanceConstant } from "presentation/constant/Parameter/ParameterMaintenanceConstant";
import { useParameterMaintenanceVM } from "presentation/hook/Parameter/useParameterMaintenanceVM";
import { useParameterMaintenanceTracked } from "presentation/store/Parameter/ParameterMaintenanceProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";


const ParameterTablePanel: React.FC = () => {
    const [paramMainState] = useParameterMaintenanceTracked();
    const paramMainVM = useParameterMaintenanceVM();
    const gridRef: any = useRef(null);


    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();

        paramMainVM.updateSelectedRows(selectedRows);
    }, [paramMainVM])

    const handleRowDoubleClick = useCallback((entity: ParameterEntity) => {
        paramMainVM.onRowDoubleClick(entity);
    }, [paramMainVM])

    const memoParameterTable = useMemo(() => {

        return (
            <NbisTable
                id='parameter-table'
                isNewColumnSetting={true}
                headerLabel={ParameterMaintenanceConstant.TITLE}
                columns={INITIAL_PARAMETER_COL_DEF.slice()}
                data={transferRowData(paramMainState.tableData) ?? []}
                showPaginator={false}
                editable={false}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                isRowHighligted={true}
                onSelectionChanged={handleSelectionChange}
                onRowDoubleClick={(e: any, entity: ParameterEntity) => handleRowDoubleClick(entity)}
                gridHeight="customHeight"
                customHeight="calc(100vh - 75px)"
                ref={gridRef}
            />
        );
    }, [handleRowDoubleClick, handleSelectionChange, paramMainState.tableData])

    return <><TableWrapper>
        {memoParameterTable}
    </TableWrapper>
    </>;
}

export default memo(ParameterTablePanel);
